@import "smart-grid.scss";

@include reset();

$grey1: rgba(37,37,37,1);
$grey2: rgba(75,75,75,1);
$grey3: rgba(160, 160, 160, 1);
$grey4: rgba(244,244,244,1);

$green1: #57CD62;
$green2: #97EA6D;
$green3: #adea6d;

$orange1: rgba(255,123,57,1);
$orange2: rgba(253,179,36,1);

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder{color: white;}
input::-moz-placeholder, textarea::-moz-placeholder{color: white; opacity: 1}
input:-moz-placeholder, textarea:-moz-placeholder{color: white; opacity: 1}
input:-ms-input-placeholder, textarea:-ms-input-placeholder{color: white;  opacity: 1}

*{
  font-family: Whitney-Medium;
  outline: none;
  font-size: 12px;
  color: $grey1;
  font-weight: 100;
}
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9{
  padding-left: 0px;
  padding-right: 0px;
}
a{
  color: $grey1;
  transition: all 0.5s ease 0s;
  &:hover{
    color: $grey1;
  }
  &:visited{
    color: $grey1;
  }
}
.pr{
  padding-right: 15px;
}
.pl{
  padding-left: 15px;
}
.deg45{
  transform: rotate(45deg);
}
.undeg45{
  transform: rotate(-45deg);
  display: flex;
  @include flex-direction(column);
  transform-origin: 55% 25%;
  width: 270px;
  margin-top: 20px;
  position: relative;
  z-index: 3;
}
.container{
  position: relative;
}
button{
  &:hover{
    background: $orange1;
  }
  &:focus{
    background: $orange1;
    border: none;
  }
  &:disabled{
    opacity: 0.5;
    background: $orange1;
  }
}
.h2{
  margin-top: 0;
  margin-bottom: 0;
}
.button{
  width: 100%;
  text-align: center;
  background: $orange1;
  min-width: 180px;
  max-width: 220px;
  color: white;
  font-size: 16px;
  border-radius: 50px;
  padding: 15px 0;
  border: none;
  cursor: pointer;
  box-shadow: none;
  &:hover{
    background: $orange1;
  }
  &:focus{
    background: $orange1;
    border: none;
  }
  &:disabled{
    opacity: 0.5;
  }
}
.form-horizontal .checkbox, .form-horizontal .radio{
  min-height: auto;
}
.form-horizontal .checkbox, .form-horizontal .checkbox-inline, .form-horizontal .radio, .form-horizontal .radio-inline{
  padding-top: 0;
}
header{
  background: url("/img/header.jpg") no-repeat 50% 50%;
  background-size: cover;
  min-height: 670px;
  position: relative;
  z-index: 3;
  .info{
    margin-top: 15px;
    display: flex;
    @include justify-content(space-between);
    @include align-items(center);
  }
  .contacts{
    text-align: right;
    .phone{
      font-size: 22px;
      &:hover{
        color: $orange1;
      }
    }
    p{
      font-size: 16px;
    }
  }
  .small-device{
    display: flex;
    @include justify-content(space-between);
    @include align-items(center);
    @include flex-direction-xs(column);
    .contacts{
      text-align: left;
    }
    .button{
      padding: 13px 0;
    }
  }
  .description{
    margin: 30px 0 0 350px;
    h2{
      font-size: 48px;
      font-family: Whitney-Black;
    }
    p{
      font-size: 25px;
      margin-top: 30px;
    }
  }
  .offer{
    width: 350px;
    height: 350px;
    border-radius: 10px;
    background: white;
    position: absolute;
    right: -40px;
    top: 310px;
    box-shadow: 0px 0px 15px rgba(37,37,37, 0.3);
    &:after{
      position: absolute;
      width: 330px;
      height: 330px;
      border: 1px solid $orange1;
      border-radius: 10px;
      content: "";
      margin: 10px;
      top: 0;
    }
    .form-title{
      font-size: 24px;
      text-align: center;
      color: $grey2;
      text-transform: uppercase;
    }
    .form-description{
      font-size: 18px;
      text-align: center;
      color: $grey2;
      margin-bottom: 15px;
    }
    input.form-control{
      box-shadow: none;
      height: auto;
      &:focus{
        box-shadow: none;
      }
    }
    .form{
      display: flex;
      @include flex-direction(column);
      input, input.form-control{
        font-size: 16px;
        background: $grey4;
        height: auto;
        box-shadow: none;
      }
      input::-webkit-input-placeholder, textarea::-webkit-input-placeholder{color: $grey3; opacity: 1}
      input::-moz-placeholder, textarea::-moz-placeholder{color: $grey3; opacity: 1}
      input:-moz-placeholder, textarea:-moz-placeholder{color: $grey3; opacity: 1}
      input:-ms-input-placeholder, textarea:-ms-input-placeholder{color: $grey3; opacity: 1}

      button{
        border: 0;
        height: 140px;
        width: 140px;
        background: $orange1 url("/img/offer-button-header.jpg") no-repeat 110% 150%;
        border-radius: 140px;
        color: white;
        font-family: Whitney-Bold;
        font-size: 22px;
        box-shadow: 0px 0px 15px rgba(253, 179, 36, 1);
        cursor: pointer;
        margin: 30px auto 0;
      }
    }
  }
}
.navbar{
  padding-top: 20px;
  .nav-item{
    color: $grey1;
    text-transform: uppercase;
    position: relative;
    z-index: 2;
    &:before{
      content: "";
      position: absolute;
      width: 25px;
      height: 25px;
      background: $orange1;
      border-radius: 25px;
      z-index: -1;
      top: 3px;
      left: -3px;
      opacity: 0;
      transition: all 0.5s ease 0s;
    }
    &:hover, &.active{
      color: $grey1;
      &:before{
        opacity: 1;
      }
    }
  }
}
#logo{
  .logo-cls{
    fill-rule: evenodd;
    fill: url(#linear-gradient);
    fill: $orange1;
  }
}
.logo{
  display: flex;
  text-decoration: none;
  transition: all 0.5s ease 0s;
  @include align-items(center);
  &:hover{
    transform: scale(1.02);
    text-decoration: none;
  }
  .logo-img{
    margin-right: 10px;
    width: 48px;
    height: 55px;
    fill: $orange1;
    text-decoration: none;
  }
  h1{
    font-family: Whitney-Bold;
    font-size: 26px;
    color: $orange1;
    text-transform: uppercase;
  }
}
.ghost-footer{
  height: 100px;
}
.wrapper{
  min-height: 100%;
  margin-bottom: -100px;
  overflow: hidden;
}
.title-box{
  display: flex;
  @include justify-content(center);
  @include align-items(flex-start);
  @include align-items-sm(center);
  @include flex-direction(column);
  /*width: 500px;*/
  margin: 40px auto 0;
  h2{
    &.title{
      font-size: 48px;
      font-family: Whitney-Black;
      text-align: center;
      position: relative;
      display: inline-block;
      z-index: 2;
      &:after{
        width: 120px;
        height: 10px;
        background: $orange1;
        position: absolute;
        content: "";
        left: -50px;
        bottom: 5px;
        z-index: -1;
      }
    }
  }
  p{
    font-size: 25px;
  }
}
.price-items{
  display: flex;
  flex-wrap: wrap;
  @include flex-direction(row);
  @include flex-direction-sm(column);
  @include justify-content(space-around);
  @include align-items(flex-start);
  @include align-items-sm(center);
  .item{
    margin-top: 50px;
    padding-left: 15px;
    padding-right: 15px;
    &:first-child{
      .img{
        .price{
          right: 50px;
        }
      }
    }
    p{
      margin: 10px 0 20px;
      color: $orange1;
      text-transform: uppercase;
      font-family: Whitney-Bold;
      font-size: 20px;
    }
    .img{
      position: relative;
      display: flex;
      @include justify-content(flex-start);
      img{
        height: 250px;
      }
      .price{
        position: absolute;
        top: 0;
        right: 0;
        width: 135px;
      }
    }
    li{
      list-style: none;
      position: relative;
      padding-left: 15px;
      color: $grey3;
      font-size: 16px;
      margin: 3px 0;
      &:after{
        content: "•";
        color: $orange1;
        position: absolute;
        top: 0px;
        left: 0;
        font-size: 16px;
      }
    }

  }
}
#advantages{
  margin-top: 50px;
  background: url("/img/advantages.jpg") no-repeat 50% 50%;
  background-size: cover;
  padding: 0 0 100px 0;
  .title-box{
    padding: 40px 0 0 0;
    margin-top: 0;
  }
  .advantages-items{
    display: flex;
    flex-wrap: wrap;
    @include justify-content(space-between);
    @include justify-content-sm(center);
    @include align-items-sm(center);
    @include flex-direction(row);
    @include flex-direction-sm(column);
    .item{
      width: 100%;
      max-width: 330px;
      min-width: 280px;
      margin-top: 50px;
      p{
        font-family: Whitney-Medium;
        font-size: 16px;
        &.advantages-title{
          font-family: Whitney-Bold;
          font-size: 16px;
          margin-bottom: 15px;
          text-transform: uppercase;
        }
      }
    }
  }
}
#calculate{
  .calculate-tab{
    display: flex;
  }
  .nav-tabs{
    border: 0;
  }
  #tab{
    counter-reset: list 0;
    display: flex;
    @include flex-direction(column);
    .nav-item{
      margin: 0;
      border: 0;
      width: 100%;

    }
    .active{
      .nav-link {
        background: $orange1;
        color: white;
      }
    }
    .nav-link{
      margin: 0;
      border: 0;
      font-size: 26px;
      color: $grey3;
      background: $grey4;
      line-height: 1;
      border-radius: 0;
      width: 400px;
      padding: 40px 75px;
      transition: all 0.5s ease 0s;
      margin-bottom: 1px;
      position: relative;
      z-index: 2;
      &:after{
        position: absolute;
        left: -25px;
        bottom: -20px;
        opacity: 0.5;
        counter-increment: list;
        content: "0"counter(list);
        font-size: 105px;
        color: white;
        font-family: Whitney-Black;
        z-index: -1;
      }
      &.active, &:hover{
        background: $orange1;
        color: white;
      }
    }
  }
  #calculate-tab-content{
    border: 10px solid $orange1;
    padding: 20px;
    .tab-pane{
      /*display: flex;
      flex-wrap: wrap;
      @include justify-content(space-between);*/
    }
    .form{
      display: flex;
      flex-wrap: wrap;
      @include justify-content(space-between);
    }
    .calculate-conf{
      width: 45%;
      transition: all 0.5s ease 0s;
      .calculate-conf-title{
        font-size: 18px;
        span{
          margin-right: 30px;
          font-size: 18px;
        }
      }
      .conf{
        margin-left: 40px;
        margin-top: 15px;
        margin-bottom: 15px;
        display: flex;
        @include flex-direction(column);
        input{
          width: auto;
          margin: 0;
          background: $grey4;
          font-size: 16px;
        }
        input::-webkit-input-placeholder, textarea::-webkit-input-placeholder{color: $grey3; opacity: 1}
        input::-moz-placeholder, textarea::-moz-placeholder{color: $grey3; opacity: 1}
        input:-moz-placeholder, textarea:-moz-placeholder{color: $grey3; opacity: 1}
        input:-ms-input-placeholder, textarea:-ms-input-placeholder{color: $grey3; opacity: 1}
        label{
          cursor: pointer;
          font-size: 16px;
          font-weight: 100;
          &:hover{
            .jq-radio{
              border: 1px solid $orange1;
            }
          }
          .checkbox, .radio{
            margin-top: 0;
            margin-bottom: 0;
          }
        }
        .column{
          display: flex;
          @include flex-direction(column);
        }
        .js-yes{
          visibility: hidden;
          opacity: 0;
          height: 0;
          transition: all 0.5s ease 0s;
          &.shown{
            opacity: 1;
            height: auto;
            visibility: visible;
          }
        }
      }
    }
  }
}

/*****/
.cp-container{
  .cp-pills{
    display: none !important;
  }
  .reset, .add, .remove{
    display: none;
  }
  .cp-swatch{
    border: transparent;
    padding: 3px;
    width: 30px;
    height: 30px;
    transition: all 0.5s ease;
    &.actual{
      box-shadow: none;
      border: 1px solid $orange1;
    }
    span{
      border-radius: 3px;
      transition: all 0.5s ease;
    }
  }
}
.cp-container .cp-swatches .cp-swatch.actual{
  box-shadow: none;
  border: 1px solid $orange1;
}
.cp-container .cp-transparency, .cp-container .cp-swatches .cp-swatch{
  background: white;
}
#offer{
  display: flex;
  @include justify-content(space-between);
  .gradient-orange{
    padding: 20px;
    width: 50%;
    background: $orange1;
    background: linear-gradient(to bottom left, $orange1, $orange2 70%);
    display: flex;
    @include justify-content(flex-end);
    position: relative;

    .img{
      left: 30%;
      bottom: 0px;
      align-self: flex-end;
      position: absolute;
      z-index: 1;
    }
    .metering{
      z-index: 2;
      .button{
        background: rgba(255,255,255,0.25);
      }
    }
  }
  .gradient-green{
    padding: 20px;
    width: 50%;
    background: $orange1;
    background: linear-gradient(to bottom right, $green1, $green3 70%);
    display: flex;
    @include justify-content(flex-start);
    position: relative;
    z-index: 2;
    .img{
      right: 30%;
      bottom: 0px;
      align-self: flex-end;
      position: absolute;
      z-index: 1;
    }
    .installment{
      z-index: 2;
    }
  }
  .installment, .metering{
    display: flex;
    @include flex-direction(column);
    @include justify-content(space-between);
  }
  h2{
    font-family: Whitney-Black;
    font-size: 48px;
    color: white;
  }
  p{
    max-width: 300px;
    color: white;
    font-size: 20px;
    &.help-block{
      font-size: 10px;
    }
  }
  input.form-control, input{
    color: white;
    font-size: 16px;
    margin: 0;
    max-width: 150px;
    border-bottom: 1px solid white;
    padding: 5px;
    border-radius: 0;
    box-shadow: none;
    height: auto;
    &:focus{
      background: transparent;
    }
  }
  input.form-control::-webkit-input-placeholder{color: white; opacity: 1}
  input.form-control::-moz-placeholder{color: white; opacity: 1}
  input.form-control:-moz-placeholder{color: white; opacity: 1}
  input.form-control:-ms-input-placeholder{color: white; opacity: 1}
  .button{
    margin-left: 10px;
  }
  .form{
    display: flex;
    @include align-items(center);
    @include justify-content(space-between);
    max-width: 355px;
  }
}

#reviews{
  .reviews-carousel{
    .ritem{
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 500px;
      position: relative;
      .review{
        width: 500px;
        height: 380px;
        margin-top: -190px;
        position: absolute;
        z-index: 2;
        background: $grey4 url("/img/review.jpg") no-repeat 50% 50%;
        background-size: cover;
        left: 0;
        top: 50%;
        padding: 10px 20px;
        p{
          font-family: Whitney-MediumItalic;
          font-size: 16px;
        }
        .person{
          display: flex;
          @include align-items(center);
          .photo{
            width: 130px;
            height: 130px;
            border-radius: 130px;
            background-size: cover;
            margin-right: 10px;
          }
          p{
            font-size: 16px;
            &.name{
              font-size: 18px;
            }
          }
        }
      }
      .img{
        height: 500px;
        width: 700px;
        z-index: 1;
        right: 0;
        position: absolute;
      }
      .carousel-inner{
        .item{
          height: 500px;
        }
      }
    }
  }
  .owl-nav{
    left: 20%;
  }
}
#ribbon{
  .st0{opacity:0.2;}
  .st1{fill:url(#SVGID_1_);}
  .st2{fill:none;}
  .st3{display:none;}
  .st4{display:none;fill:none;stroke:#FFFFFF;stroke-miterlimit:10;}
}
.stop-color-1{
  stop-color: $green1;
}
.stop-color-2{
  stop-color: $green2;
}
#carousel{
  background: url("/img/carousel.jpg") no-repeat 50% 50%;
  background-size: cover;
  .title-box{
    padding: 40px auto 0;
  }
  .carousel-items{
    padding-top: 45px;
    .item{
      height: 300px;
    }
  }
}
.owl-nav{
  display: flex;
  @include justify-content(center);
  @include align-items(center);

  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -50px;
}
.owl-carousel .owl-dot, .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-nav .owl-prev{
  background: $orange1;
  width: 50px;
  height: 50px;
  text-align: center;
  opacity: 0.7;
  transition: all 0.5s ease 0s;
  margin: 0px 1px;
  color: white;
  &:hover{
    opacity: 1;
  }
}
.owl-carousel .owl-nav .owl-prev{
  background: $orange1 url("/img/arrow-l.png") no-repeat 50% 50%;
}
.owl-carousel .owl-nav .owl-next{
  background: $orange1 url("/img/arrow-r.png") no-repeat 50% 50%;
}
#questions{
  display: flex;
  @include justify-content(center);
  @include align-items(center);
  .box{
    border: 10px solid rgba(226,226,226,1);
    max-width: 770px;
    width: 100%;
    min-width: 300px;
    position: relative;
    height: 110px;
    margin-top: 75px;
    margin-bottom: 60px;
    .title{
      position: relative;
      text-align: center;
      top: -48px;
      .h2{
        text-align: center;
        font-size: 48px;
        font-family: Whitney-Black;
        text-align: center;
        position: relative;
        display: inline-block;
        z-index: 2;
        &:after{
          width: 240px;
          height: 10px;
          background: white;
          position: absolute;
          content: "";
          left: 50%;
          margin-left: -120px;
          bottom: 5px;
          z-index: -1;
        }
      }
      p{
        font-size: 25px;
      }
    }
    .button{
      margin: 0 auto;
      text-align: center;
      display: block;
      position: relative;
      top: -29px;
    }
  }
}
footer {
  .line{
    height: 2px;
    width: 100%;
    background: rgba(226,226,226,1);
  }
  .info{
    display: flex;
    @include justify-content(space-between);
    @include align-items(center);
    @include flex-direction-xs(column);
    @include justify-content-xs(center);
    margin: 15px 0;
    .copy{
      color: $grey3;
    }
    .contacts{
      text-align: right;
    }
    p, .promicom a{
      font-size: 16px;
    }
    .phone{
      font-size: 22px;
    }
    a.promicom-name{
      font-family: Whitney-Bold;
      font-size: 18px;
      color: $orange1;
    }

  }
}
.navbar-toggler{
  display: none;
  align-self: center;
  cursor: pointer;
  .navbar-toggler-icon{
    height: 4px;
    width: 25px;
    background: $orange1;
    display: block;
    margin: 2px;
  }
}
.form{
  input{
    border: 0;
    background: 0;
    width: 100%;
    padding: 15px;
    border-radius: 5px;
    margin-top: 15px;
    font-weight: 100;
  }
  input[type="radio"]{

  }
  .jq-radio{
    transition: all 0.5s ease 0s;
    background: transparent;
    border: 1px solid white;
    box-shadow: none;
    width: 16px;
    height: 16px;
    .jq-radio__div{
      margin: 0;
      background: #ffaf88;
      width: 10px;
      height: 10px;
      box-shadow: none;
      border-radius: 10px;
      margin: 2px 0 0 2px;
      transition: all 0.1s ease 0s;
    }
    &.checked{
      border: 1px solid $orange1;
      width: 16px;
      height: 16px;
      .jq-radio__div{
        background: $orange1;
        width: 8px;
        height: 8px;
        margin: 3px 0 0 3px;
      }
    }
    &:hover, &:focus{
      border: 1px solid $orange1;
    }
  }
}
.modal-header{
  display: flex;
  @include flex-direction(column);
  text-align: center;
  h5{
    font-size: 30px;
    margin-bottom: 15px;
  }
  p{
    font-size: 16px;
  }
}
.modal-body{
  input.form-control{
    font-size: 16px;
    background: $grey4;
    border: 0;
    width: 100%;
    padding: 15px;
    border-radius: 5px;
    margin-top: 15px;
    font-weight: 100;
    height: auto;
    box-shadow: none;
    width: 100%;
    &:focus{
      box-shadow: none;
    }
  }
  .elements{
    display: flex;
    @include flex-direction(column);
    @include justify-content(center);
    @include align-items(center);
  }
  label{
    font-weight: 100;
    width: 100%;
    p{
      font-size: 16px;
    }
  }
}
.help-block{
  color: $orange1;
  font-size: 10px;
  margin: 0;
  text-align: center;
}
.modal-content{
  border-radius: 0;
  border: 5px solid $orange1;
}
.close, .close-img {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  opacity: .5;
  position: absolute;
  top: 5px;
  right: 7px;
  height: 13px;
  width: 13px;
  background: url(/img/icon/none.svg) no-repeat;
}

@media (max-width: 1400px) {
  #offer{
    .gradient-orange{
      .img{
        left: 50px;
      }
    }
    .gradient-green{
      .img{
        right: 50px;
      }
    }
  }
}
@media (max-width: 1200px) {
  header{
    .offer{
      top: 410px;
    }
  }
  #calculate #calculate-tab-content .calculate-conf .conf{
    margin-left: 0;
  }
}
@media (max-width: 1100px) {
  #offer{
    .gradient-orange, .gradient-green{
      @include justify-content(center);
    }
    .img{
      display: none;
    }
  }
}
@media (max-width: 991px) {
  #MainNavbarToggler{
    text-align: right;
    &.navbar-collapse.collapse{
      display: none !important;
    }
    &.navbar-collapse.in{
      display: block !important;
    }
    .navbar-nav{
      float: right;
    }
    .navbar-nav > li > a{
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  .navbar{
    .nav-item{
      &:before{
        left: auto;
        right: -3px;
      }
    }
  }
  header{
    .description{
      margin: 40px 0 0 250px;
      h2{
        font-size: 30px;
      }
      p{
        font-size: 23px;
        margin-top: 20px;
      }
    }
    .offer{
      top: 290px;
      right: auto;
      left: -85px;
    }
  }
  #calculate{
    #calculate-tab-content{
      position: relative;
      z-index: 3;
    }
    #tab{
      @include flex-direction(row);
      .nav-link{
        font-size: 16px;
        width: auto;
        padding: 10px;
        display: block;
        height: 100%;
        &:after{
          font-size: 60px;
        }
      }
    }
    .calculate-tab{
      @include flex-direction(column);
    }
  }
}

@media (max-width: 780px) {
  header{
    background-position: 60% 50%;
    .description{
      margin: 40px 0 0 0px;
      h2{
        text-align: center;
      }
      p{
        font-size: 18px;
        margin-top: 15px;
        text-align: center;
      }
    }
    .offer{
      top: 420px;
      right: auto;
      left: 50%;
      margin-left: -175px;
    }
  }
  #price{
    padding-top: 150px;
  }
  #calculate{
    #calculate-tab-content{
      position: relative;
      z-index: 3;
    }
    #tab{
      @include flex-direction(column);
      .nav-link{
        font-size: 16px;
        width: 100%;
        text-align: center;
        padding: 10px;
        display: block;
        height: 100%;
        &:after{
          font-size: 60px;
        }
      }
    }
    .calculate-tab{
      @include flex-direction(column);
    }
  }
  #reviews{
    .owl-nav{
      left: 50%;
    }
    .reviews-carousel{
      .ritem{
        @include flex-direction(column);
        @include justify-content(center);
        .review{
          position: absolute;
          top: 50%;
          height: auto;
          width: 100%;
          margin-top: -200px;
        }
        .img{
          position: absolute;
          width: 100%;
        }
      }
    }
  }
  .title-box, #offer{
    h2{
      font-size: 35px;
      &.title{
        font-size: 35px;
      }
    }
    p{
      font-size: 20px;
      text-align: center;
    }
  }
  #questions .box .title{
    p{
      font-size: 20px;
      &.h2{
        font-size: 35px;
      }
    }
  }
}
@media (max-width: 720px) {
  #offer{
    @include flex-direction(column);
    .gradient-orange, .gradient-green{
      width: 100%;
      @include justify-content(center);
    }
    .gradient-orange{
      @include justify-content(flex-end);
    }
    .gradient-green{
      @include justify-content(flex-start);
    }
    .img{
      display: block;
    }
  }
}

@media (max-width: 560px) {
  header{
    .small-device, .small-device .contacts{
      text-align: center;
      .button{
        margin-top: 15px;
      }
    }
    .offer{
      top: 495px;
    }
  }
  #offer{
    .gradient-orange, .gradient-green{
      @include justify-content(center);
      width: 100%;
    }
    .metering, .installment{
      width: 100%;
    }
    .img{
      display: none;
    }
    h2, p{
      text-align: center;
    }
    p{
      width: 100%;
      max-width: 100%;
      margin: 15px 0;
    }
    .form{
      width: 100%;
      max-width: 100%;
    }
  }
  #questions{
    .box{
      height: auto;
    }
  }
  #price{
    padding-top: 230px;
  }
  footer{
    .info{
      .copy{
        text-align: center;
      }
      .promicom{
        width: 100%;
        text-align: center;
      }
      .pr{
        padding-right: 0;
        .contacts{
          text-align: center;
        }
      }
    }
  }

  #calculate{
    #calculate-tab-content{
      position: relative;
      z-index: 3;
      .form{
        @include flex-direction(column);
        @include justify-content(center);
        @include align-items(center);
      }
      .calculate-conf{
        width: 100%;
      }
    }
    #tab{
      @include flex-direction(column);
      .nav-link{
        font-size: 16px;
        width: 100%;
        text-align: center;
        padding: 10px;
        display: block;
        height: 100%;
        &:after{
          font-size: 60px;
        }
      }
    }
    .calculate-tab{
      @include flex-direction(column);
      .conf{
        @include justify-content(center);
        @include align-items(center);
        width: 100%;
      }

    }
  }
}
@media (max-width: 360px) {
  #offer{
    .form{
      @include flex-direction(column);
    }
  }
}

$fonts: Whitney-Medium, Whitney-Black, Whitney-Bold, Whitney-MediumItalic;

@mixin loadFonts($fonts) {
  @each $font in $fonts
  {
    @font-face {
      font-family: $font;
      src: url('/fonts/#{$font}.eot?#iefix') format('embedded-opentype'), url('/fonts/#{$font}.otf') format('opentype'),
      url('/fonts/#{$font}.woff') format('woff'), url('/fonts/#{$font}.ttf') format('truetype'), url('/fonts/#{$font}.svg##{$font}') format('svg');
      font-weight: normal;
      font-style: normal;
    }
  }
}

@include loadFonts($fonts);