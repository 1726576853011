@charset "UTF-8";
body, div, dl, dt, dd, ul, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, input, textarea, p, blockquote, th, td {
  margin: 0;
  padding: 0; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

fieldset, img, abbr {
  border: 0; }

address, caption, cite, code, dfn, em, strong, th, var {
  font-style: normal;
  font-weight: normal; }

ul li {
  list-style: none; }

caption, th {
  text-align: left; }

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal; }

sup {
  vertical-align: text-top; }

sub {
  vertical-align: text-bottom; }

input, textarea, select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit; }

legend {
  color: #000; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, main {
  display: block; }

img {
  max-width: 100%;
  height: auto; }

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  color: white; }

input::-moz-placeholder, textarea::-moz-placeholder {
  color: white;
  opacity: 1; }

input:-moz-placeholder, textarea:-moz-placeholder {
  color: white;
  opacity: 1; }

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  color: white;
  opacity: 1; }

* {
  font-family: Whitney-Medium;
  outline: none;
  font-size: 12px;
  color: #252525;
  font-weight: 100; }

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9 {
  padding-left: 0px;
  padding-right: 0px; }

a {
  color: #252525;
  transition: all 0.5s ease 0s; }
  a:hover {
    color: #252525; }
  a:visited {
    color: #252525; }

.pr {
  padding-right: 15px; }

.pl {
  padding-left: 15px; }

.deg45 {
  transform: rotate(45deg); }

.undeg45 {
  transform: rotate(-45deg);
  display: flex;
  flex-direction: column;
  transform-origin: 55% 25%;
  width: 270px;
  margin-top: 20px;
  position: relative;
  z-index: 3; }

.container {
  position: relative; }

button:hover {
  background: #ff7b39; }

button:focus {
  background: #ff7b39;
  border: none; }

button:disabled {
  opacity: 0.5;
  background: #ff7b39; }

.h2 {
  margin-top: 0;
  margin-bottom: 0; }

.button {
  width: 100%;
  text-align: center;
  background: #ff7b39;
  min-width: 180px;
  max-width: 220px;
  color: white;
  font-size: 16px;
  border-radius: 50px;
  padding: 15px 0;
  border: none;
  cursor: pointer;
  box-shadow: none; }
  .button:hover {
    background: #ff7b39; }
  .button:focus {
    background: #ff7b39;
    border: none; }
  .button:disabled {
    opacity: 0.5; }

.form-horizontal .checkbox, .form-horizontal .radio {
  min-height: auto; }

.form-horizontal .checkbox, .form-horizontal .checkbox-inline, .form-horizontal .radio, .form-horizontal .radio-inline {
  padding-top: 0; }

header {
  background: url("/img/header.jpg") no-repeat 50% 50%;
  background-size: cover;
  min-height: 670px;
  position: relative;
  z-index: 3; }
  header .info {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  header .contacts {
    text-align: right; }
    header .contacts .phone {
      font-size: 22px; }
      header .contacts .phone:hover {
        color: #ff7b39; }
    header .contacts p {
      font-size: 16px; }
  header .small-device {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media screen and (max-width: 560px) {
      header .small-device {
        flex-direction: column; } }
    header .small-device .contacts {
      text-align: left; }
    header .small-device .button {
      padding: 13px 0; }
  header .description {
    margin: 30px 0 0 350px; }
    header .description h2 {
      font-size: 48px;
      font-family: Whitney-Black; }
    header .description p {
      font-size: 25px;
      margin-top: 30px; }
  header .offer {
    width: 350px;
    height: 350px;
    border-radius: 10px;
    background: white;
    position: absolute;
    right: -40px;
    top: 310px;
    box-shadow: 0px 0px 15px rgba(37, 37, 37, 0.3); }
    header .offer:after {
      position: absolute;
      width: 330px;
      height: 330px;
      border: 1px solid #ff7b39;
      border-radius: 10px;
      content: "";
      margin: 10px;
      top: 0; }
    header .offer .form-title {
      font-size: 24px;
      text-align: center;
      color: #4b4b4b;
      text-transform: uppercase; }
    header .offer .form-description {
      font-size: 18px;
      text-align: center;
      color: #4b4b4b;
      margin-bottom: 15px; }
    header .offer input.form-control {
      box-shadow: none;
      height: auto; }
      header .offer input.form-control:focus {
        box-shadow: none; }
    header .offer .form {
      display: flex;
      flex-direction: column; }
      header .offer .form input, header .offer .form input.form-control {
        font-size: 16px;
        background: #f4f4f4;
        height: auto;
        box-shadow: none; }
      header .offer .form input::-webkit-input-placeholder, header .offer .form textarea::-webkit-input-placeholder {
        color: #a0a0a0;
        opacity: 1; }
      header .offer .form input::-moz-placeholder, header .offer .form textarea::-moz-placeholder {
        color: #a0a0a0;
        opacity: 1; }
      header .offer .form input:-moz-placeholder, header .offer .form textarea:-moz-placeholder {
        color: #a0a0a0;
        opacity: 1; }
      header .offer .form input:-ms-input-placeholder, header .offer .form textarea:-ms-input-placeholder {
        color: #a0a0a0;
        opacity: 1; }
      header .offer .form button {
        border: 0;
        height: 140px;
        width: 140px;
        background: #ff7b39 url("/img/offer-button-header.jpg") no-repeat 110% 150%;
        border-radius: 140px;
        color: white;
        font-family: Whitney-Bold;
        font-size: 22px;
        box-shadow: 0px 0px 15px #fdb324;
        cursor: pointer;
        margin: 30px auto 0; }

.navbar {
  padding-top: 20px; }
  .navbar .nav-item {
    color: #252525;
    text-transform: uppercase;
    position: relative;
    z-index: 2; }
    .navbar .nav-item:before {
      content: "";
      position: absolute;
      width: 25px;
      height: 25px;
      background: #ff7b39;
      border-radius: 25px;
      z-index: -1;
      top: 3px;
      left: -3px;
      opacity: 0;
      transition: all 0.5s ease 0s; }
    .navbar .nav-item:hover, .navbar .nav-item.active {
      color: #252525; }
      .navbar .nav-item:hover:before, .navbar .nav-item.active:before {
        opacity: 1; }

#logo .logo-cls {
  fill-rule: evenodd;
  fill: url(#linear-gradient);
  fill: #ff7b39; }

.logo {
  display: flex;
  text-decoration: none;
  transition: all 0.5s ease 0s;
  align-items: center; }
  .logo:hover {
    transform: scale(1.02);
    text-decoration: none; }
  .logo .logo-img {
    margin-right: 10px;
    width: 48px;
    height: 55px;
    fill: #ff7b39;
    text-decoration: none; }
  .logo h1 {
    font-family: Whitney-Bold;
    font-size: 26px;
    color: #ff7b39;
    text-transform: uppercase; }

.ghost-footer {
  height: 100px; }

.wrapper {
  min-height: 100%;
  margin-bottom: -100px;
  overflow: hidden; }

.title-box {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  /*width: 500px;*/
  margin: 40px auto 0; }
  @media screen and (max-width: 780px) {
    .title-box {
      align-items: center; } }
  .title-box h2.title {
    font-size: 48px;
    font-family: Whitney-Black;
    text-align: center;
    position: relative;
    display: inline-block;
    z-index: 2; }
    .title-box h2.title:after {
      width: 120px;
      height: 10px;
      background: #ff7b39;
      position: absolute;
      content: "";
      left: -50px;
      bottom: 5px;
      z-index: -1; }
  .title-box p {
    font-size: 25px; }

.price-items {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start; }
  @media screen and (max-width: 780px) {
    .price-items {
      flex-direction: column; } }
  @media screen and (max-width: 780px) {
    .price-items {
      align-items: center; } }
  .price-items .item {
    margin-top: 50px;
    padding-left: 15px;
    padding-right: 15px; }
    .price-items .item:first-child .img .price {
      right: 50px; }
    .price-items .item p {
      margin: 10px 0 20px;
      color: #ff7b39;
      text-transform: uppercase;
      font-family: Whitney-Bold;
      font-size: 20px; }
    .price-items .item .img {
      position: relative;
      display: flex;
      justify-content: flex-start; }
      .price-items .item .img img {
        height: 250px; }
      .price-items .item .img .price {
        position: absolute;
        top: 0;
        right: 0;
        width: 135px; }
    .price-items .item li {
      list-style: none;
      position: relative;
      padding-left: 15px;
      color: #a0a0a0;
      font-size: 16px;
      margin: 3px 0; }
      .price-items .item li:after {
        content: "•";
        color: #ff7b39;
        position: absolute;
        top: 0px;
        left: 0;
        font-size: 16px; }

#advantages {
  margin-top: 50px;
  background: url("/img/advantages.jpg") no-repeat 50% 50%;
  background-size: cover;
  padding: 0 0 100px 0; }
  #advantages .title-box {
    padding: 40px 0 0 0;
    margin-top: 0; }
  #advantages .advantages-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row; }
    @media screen and (max-width: 780px) {
      #advantages .advantages-items {
        justify-content: center; } }
    @media screen and (max-width: 780px) {
      #advantages .advantages-items {
        align-items: center; } }
    @media screen and (max-width: 780px) {
      #advantages .advantages-items {
        flex-direction: column; } }
    #advantages .advantages-items .item {
      width: 100%;
      max-width: 330px;
      min-width: 280px;
      margin-top: 50px; }
      #advantages .advantages-items .item p {
        font-family: Whitney-Medium;
        font-size: 16px; }
        #advantages .advantages-items .item p.advantages-title {
          font-family: Whitney-Bold;
          font-size: 16px;
          margin-bottom: 15px;
          text-transform: uppercase; }

#calculate .calculate-tab {
  display: flex; }

#calculate .nav-tabs {
  border: 0; }

#calculate #tab {
  counter-reset: list 0;
  display: flex;
  flex-direction: column; }
  #calculate #tab .nav-item {
    margin: 0;
    border: 0;
    width: 100%; }
  #calculate #tab .active .nav-link {
    background: #ff7b39;
    color: white; }
  #calculate #tab .nav-link {
    margin: 0;
    border: 0;
    font-size: 26px;
    color: #a0a0a0;
    background: #f4f4f4;
    line-height: 1;
    border-radius: 0;
    width: 400px;
    padding: 40px 75px;
    transition: all 0.5s ease 0s;
    margin-bottom: 1px;
    position: relative;
    z-index: 2; }
    #calculate #tab .nav-link:after {
      position: absolute;
      left: -25px;
      bottom: -20px;
      opacity: 0.5;
      counter-increment: list;
      content: "0" counter(list);
      font-size: 105px;
      color: white;
      font-family: Whitney-Black;
      z-index: -1; }
    #calculate #tab .nav-link.active, #calculate #tab .nav-link:hover {
      background: #ff7b39;
      color: white; }

#calculate #calculate-tab-content {
  border: 10px solid #ff7b39;
  padding: 20px; }
  #calculate #calculate-tab-content .tab-pane {
    /*display: flex;
      flex-wrap: wrap;
      @include justify-content(space-between);*/ }
  #calculate #calculate-tab-content .form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
  #calculate #calculate-tab-content .calculate-conf {
    width: 45%;
    transition: all 0.5s ease 0s; }
    #calculate #calculate-tab-content .calculate-conf .calculate-conf-title {
      font-size: 18px; }
      #calculate #calculate-tab-content .calculate-conf .calculate-conf-title span {
        margin-right: 30px;
        font-size: 18px; }
    #calculate #calculate-tab-content .calculate-conf .conf {
      margin-left: 40px;
      margin-top: 15px;
      margin-bottom: 15px;
      display: flex;
      flex-direction: column; }
      #calculate #calculate-tab-content .calculate-conf .conf input {
        width: auto;
        margin: 0;
        background: #f4f4f4;
        font-size: 16px; }
      #calculate #calculate-tab-content .calculate-conf .conf input::-webkit-input-placeholder, #calculate #calculate-tab-content .calculate-conf .conf textarea::-webkit-input-placeholder {
        color: #a0a0a0;
        opacity: 1; }
      #calculate #calculate-tab-content .calculate-conf .conf input::-moz-placeholder, #calculate #calculate-tab-content .calculate-conf .conf textarea::-moz-placeholder {
        color: #a0a0a0;
        opacity: 1; }
      #calculate #calculate-tab-content .calculate-conf .conf input:-moz-placeholder, #calculate #calculate-tab-content .calculate-conf .conf textarea:-moz-placeholder {
        color: #a0a0a0;
        opacity: 1; }
      #calculate #calculate-tab-content .calculate-conf .conf input:-ms-input-placeholder, #calculate #calculate-tab-content .calculate-conf .conf textarea:-ms-input-placeholder {
        color: #a0a0a0;
        opacity: 1; }
      #calculate #calculate-tab-content .calculate-conf .conf label {
        cursor: pointer;
        font-size: 16px;
        font-weight: 100; }
        #calculate #calculate-tab-content .calculate-conf .conf label:hover .jq-radio {
          border: 1px solid #ff7b39; }
        #calculate #calculate-tab-content .calculate-conf .conf label .checkbox, #calculate #calculate-tab-content .calculate-conf .conf label .radio {
          margin-top: 0;
          margin-bottom: 0; }
      #calculate #calculate-tab-content .calculate-conf .conf .column {
        display: flex;
        flex-direction: column; }
      #calculate #calculate-tab-content .calculate-conf .conf .js-yes {
        visibility: hidden;
        opacity: 0;
        height: 0;
        transition: all 0.5s ease 0s; }
        #calculate #calculate-tab-content .calculate-conf .conf .js-yes.shown {
          opacity: 1;
          height: auto;
          visibility: visible; }

/*****/
.cp-container .cp-pills {
  display: none !important; }

.cp-container .reset, .cp-container .add, .cp-container .remove {
  display: none; }

.cp-container .cp-swatch {
  border: transparent;
  padding: 3px;
  width: 30px;
  height: 30px;
  transition: all 0.5s ease; }
  .cp-container .cp-swatch.actual {
    box-shadow: none;
    border: 1px solid #ff7b39; }
  .cp-container .cp-swatch span {
    border-radius: 3px;
    transition: all 0.5s ease; }

.cp-container .cp-swatches .cp-swatch.actual {
  box-shadow: none;
  border: 1px solid #ff7b39; }

.cp-container .cp-transparency, .cp-container .cp-swatches .cp-swatch {
  background: white; }

#offer {
  display: flex;
  justify-content: space-between; }
  #offer .gradient-orange {
    padding: 20px;
    width: 50%;
    background: #ff7b39;
    background: linear-gradient(to bottom left, #ff7b39, #fdb324 70%);
    display: flex;
    justify-content: flex-end;
    position: relative; }
    #offer .gradient-orange .img {
      left: 30%;
      bottom: 0px;
      align-self: flex-end;
      position: absolute;
      z-index: 1; }
    #offer .gradient-orange .metering {
      z-index: 2; }
      #offer .gradient-orange .metering .button {
        background: rgba(255, 255, 255, 0.25); }
  #offer .gradient-green {
    padding: 20px;
    width: 50%;
    background: #ff7b39;
    background: linear-gradient(to bottom right, #57CD62, #adea6d 70%);
    display: flex;
    justify-content: flex-start;
    position: relative;
    z-index: 2; }
    #offer .gradient-green .img {
      right: 30%;
      bottom: 0px;
      align-self: flex-end;
      position: absolute;
      z-index: 1; }
    #offer .gradient-green .installment {
      z-index: 2; }
  #offer .installment, #offer .metering {
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
  #offer h2 {
    font-family: Whitney-Black;
    font-size: 48px;
    color: white; }
  #offer p {
    max-width: 300px;
    color: white;
    font-size: 20px; }
    #offer p.help-block {
      font-size: 10px; }
  #offer input.form-control, #offer input {
    color: white;
    font-size: 16px;
    margin: 0;
    max-width: 150px;
    border-bottom: 1px solid white;
    padding: 5px;
    border-radius: 0;
    box-shadow: none;
    height: auto; }
    #offer input.form-control:focus, #offer input:focus {
      background: transparent; }
  #offer input.form-control::-webkit-input-placeholder {
    color: white;
    opacity: 1; }
  #offer input.form-control::-moz-placeholder {
    color: white;
    opacity: 1; }
  #offer input.form-control:-moz-placeholder {
    color: white;
    opacity: 1; }
  #offer input.form-control:-ms-input-placeholder {
    color: white;
    opacity: 1; }
  #offer .button {
    margin-left: 10px; }
  #offer .form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 355px; }

#reviews .reviews-carousel .ritem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 500px;
  position: relative; }
  #reviews .reviews-carousel .ritem .review {
    width: 500px;
    height: 380px;
    margin-top: -190px;
    position: absolute;
    z-index: 2;
    background: #f4f4f4 url("/img/review.jpg") no-repeat 50% 50%;
    background-size: cover;
    left: 0;
    top: 50%;
    padding: 10px 20px; }
    #reviews .reviews-carousel .ritem .review p {
      font-family: Whitney-MediumItalic;
      font-size: 16px; }
    #reviews .reviews-carousel .ritem .review .person {
      display: flex;
      align-items: center; }
      #reviews .reviews-carousel .ritem .review .person .photo {
        width: 130px;
        height: 130px;
        border-radius: 130px;
        background-size: cover;
        margin-right: 10px; }
      #reviews .reviews-carousel .ritem .review .person p {
        font-size: 16px; }
        #reviews .reviews-carousel .ritem .review .person p.name {
          font-size: 18px; }
  #reviews .reviews-carousel .ritem .img {
    height: 500px;
    width: 700px;
    z-index: 1;
    right: 0;
    position: absolute; }
  #reviews .reviews-carousel .ritem .carousel-inner .item {
    height: 500px; }

#reviews .owl-nav {
  left: 20%; }

#ribbon .st0 {
  opacity: 0.2; }

#ribbon .st1 {
  fill: url(#SVGID_1_); }

#ribbon .st2 {
  fill: none; }

#ribbon .st3 {
  display: none; }

#ribbon .st4 {
  display: none;
  fill: none;
  stroke: #FFFFFF;
  stroke-miterlimit: 10; }

.stop-color-1 {
  stop-color: #57CD62; }

.stop-color-2 {
  stop-color: #97EA6D; }

#carousel {
  background: url("/img/carousel.jpg") no-repeat 50% 50%;
  background-size: cover; }
  #carousel .title-box {
    padding: 40px auto 0; }
  #carousel .carousel-items {
    padding-top: 45px; }
    #carousel .carousel-items .item {
      height: 300px; }

.owl-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -50px; }

.owl-carousel .owl-dot, .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-nav .owl-prev {
  background: #ff7b39;
  width: 50px;
  height: 50px;
  text-align: center;
  opacity: 0.7;
  transition: all 0.5s ease 0s;
  margin: 0px 1px;
  color: white; }
  .owl-carousel .owl-dot:hover, .owl-carousel .owl-nav .owl-next:hover, .owl-carousel .owl-nav .owl-prev:hover {
    opacity: 1; }

.owl-carousel .owl-nav .owl-prev {
  background: #ff7b39 url("/img/arrow-l.png") no-repeat 50% 50%; }

.owl-carousel .owl-nav .owl-next {
  background: #ff7b39 url("/img/arrow-r.png") no-repeat 50% 50%; }

#questions {
  display: flex;
  justify-content: center;
  align-items: center; }
  #questions .box {
    border: 10px solid #e2e2e2;
    max-width: 770px;
    width: 100%;
    min-width: 300px;
    position: relative;
    height: 110px;
    margin-top: 75px;
    margin-bottom: 60px; }
    #questions .box .title {
      position: relative;
      text-align: center;
      top: -48px; }
      #questions .box .title .h2 {
        text-align: center;
        font-size: 48px;
        font-family: Whitney-Black;
        text-align: center;
        position: relative;
        display: inline-block;
        z-index: 2; }
        #questions .box .title .h2:after {
          width: 240px;
          height: 10px;
          background: white;
          position: absolute;
          content: "";
          left: 50%;
          margin-left: -120px;
          bottom: 5px;
          z-index: -1; }
      #questions .box .title p {
        font-size: 25px; }
    #questions .box .button {
      margin: 0 auto;
      text-align: center;
      display: block;
      position: relative;
      top: -29px; }

footer .line {
  height: 2px;
  width: 100%;
  background: #e2e2e2; }

footer .info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0; }
  @media screen and (max-width: 560px) {
    footer .info {
      flex-direction: column; } }
  @media screen and (max-width: 560px) {
    footer .info {
      justify-content: center; } }
  footer .info .copy {
    color: #a0a0a0; }
  footer .info .contacts {
    text-align: right; }
  footer .info p, footer .info .promicom a {
    font-size: 16px; }
  footer .info .phone {
    font-size: 22px; }
  footer .info a.promicom-name {
    font-family: Whitney-Bold;
    font-size: 18px;
    color: #ff7b39; }

.navbar-toggler {
  display: none;
  align-self: center;
  cursor: pointer; }
  .navbar-toggler .navbar-toggler-icon {
    height: 4px;
    width: 25px;
    background: #ff7b39;
    display: block;
    margin: 2px; }

.form input {
  border: 0;
  background: 0;
  width: 100%;
  padding: 15px;
  border-radius: 5px;
  margin-top: 15px;
  font-weight: 100; }

.form .jq-radio {
  transition: all 0.5s ease 0s;
  background: transparent;
  border: 1px solid white;
  box-shadow: none;
  width: 16px;
  height: 16px; }
  .form .jq-radio .jq-radio__div {
    margin: 0;
    background: #ffaf88;
    width: 10px;
    height: 10px;
    box-shadow: none;
    border-radius: 10px;
    margin: 2px 0 0 2px;
    transition: all 0.1s ease 0s; }
  .form .jq-radio.checked {
    border: 1px solid #ff7b39;
    width: 16px;
    height: 16px; }
    .form .jq-radio.checked .jq-radio__div {
      background: #ff7b39;
      width: 8px;
      height: 8px;
      margin: 3px 0 0 3px; }
  .form .jq-radio:hover, .form .jq-radio:focus {
    border: 1px solid #ff7b39; }

.modal-header {
  display: flex;
  flex-direction: column;
  text-align: center; }
  .modal-header h5 {
    font-size: 30px;
    margin-bottom: 15px; }
  .modal-header p {
    font-size: 16px; }

.modal-body input.form-control {
  font-size: 16px;
  background: #f4f4f4;
  border: 0;
  width: 100%;
  padding: 15px;
  border-radius: 5px;
  margin-top: 15px;
  font-weight: 100;
  height: auto;
  box-shadow: none;
  width: 100%; }
  .modal-body input.form-control:focus {
    box-shadow: none; }

.modal-body .elements {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.modal-body label {
  font-weight: 100;
  width: 100%; }
  .modal-body label p {
    font-size: 16px; }

.help-block {
  color: #ff7b39;
  font-size: 10px;
  margin: 0;
  text-align: center; }

.modal-content {
  border-radius: 0;
  border: 5px solid #ff7b39; }

.close, .close-img {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  opacity: .5;
  position: absolute;
  top: 5px;
  right: 7px;
  height: 13px;
  width: 13px;
  background: url(/img/icon/none.svg) no-repeat; }

@media (max-width: 1400px) {
  #offer .gradient-orange .img {
    left: 50px; }
  #offer .gradient-green .img {
    right: 50px; } }

@media (max-width: 1200px) {
  header .offer {
    top: 410px; }
  #calculate #calculate-tab-content .calculate-conf .conf {
    margin-left: 0; } }

@media (max-width: 1100px) {
  #offer .gradient-orange, #offer .gradient-green {
    justify-content: center; }
  #offer .img {
    display: none; } }

@media (max-width: 991px) {
  #MainNavbarToggler {
    text-align: right; }
    #MainNavbarToggler.navbar-collapse.collapse {
      display: none !important; }
    #MainNavbarToggler.navbar-collapse.in {
      display: block !important; }
    #MainNavbarToggler .navbar-nav {
      float: right; }
    #MainNavbarToggler .navbar-nav > li > a {
      padding-top: 10px;
      padding-bottom: 10px; }
  .navbar .nav-item:before {
    left: auto;
    right: -3px; }
  header .description {
    margin: 40px 0 0 250px; }
    header .description h2 {
      font-size: 30px; }
    header .description p {
      font-size: 23px;
      margin-top: 20px; }
  header .offer {
    top: 290px;
    right: auto;
    left: -85px; }
  #calculate #calculate-tab-content {
    position: relative;
    z-index: 3; }
  #calculate #tab {
    flex-direction: row; }
    #calculate #tab .nav-link {
      font-size: 16px;
      width: auto;
      padding: 10px;
      display: block;
      height: 100%; }
      #calculate #tab .nav-link:after {
        font-size: 60px; }
  #calculate .calculate-tab {
    flex-direction: column; } }

@media (max-width: 780px) {
  header {
    background-position: 60% 50%; }
    header .description {
      margin: 40px 0 0 0px; }
      header .description h2 {
        text-align: center; }
      header .description p {
        font-size: 18px;
        margin-top: 15px;
        text-align: center; }
    header .offer {
      top: 420px;
      right: auto;
      left: 50%;
      margin-left: -175px; }
  #price {
    padding-top: 150px; }
  #calculate #calculate-tab-content {
    position: relative;
    z-index: 3; }
  #calculate #tab {
    flex-direction: column; }
    #calculate #tab .nav-link {
      font-size: 16px;
      width: 100%;
      text-align: center;
      padding: 10px;
      display: block;
      height: 100%; }
      #calculate #tab .nav-link:after {
        font-size: 60px; }
  #calculate .calculate-tab {
    flex-direction: column; }
  #reviews .owl-nav {
    left: 50%; }
  #reviews .reviews-carousel .ritem {
    flex-direction: column;
    justify-content: center; }
    #reviews .reviews-carousel .ritem .review {
      position: absolute;
      top: 50%;
      height: auto;
      width: 100%;
      margin-top: -200px; }
    #reviews .reviews-carousel .ritem .img {
      position: absolute;
      width: 100%; }
  .title-box h2, #offer h2 {
    font-size: 35px; }
    .title-box h2.title, #offer h2.title {
      font-size: 35px; }
  .title-box p, #offer p {
    font-size: 20px;
    text-align: center; }
  #questions .box .title p {
    font-size: 20px; }
    #questions .box .title p.h2 {
      font-size: 35px; } }

@media (max-width: 720px) {
  #offer {
    flex-direction: column; }
    #offer .gradient-orange, #offer .gradient-green {
      width: 100%;
      justify-content: center; }
    #offer .gradient-orange {
      justify-content: flex-end; }
    #offer .gradient-green {
      justify-content: flex-start; }
    #offer .img {
      display: block; } }

@media (max-width: 560px) {
  header .small-device, header .small-device .contacts {
    text-align: center; }
    header .small-device .button, header .small-device .contacts .button {
      margin-top: 15px; }
  header .offer {
    top: 495px; }
  #offer .gradient-orange, #offer .gradient-green {
    justify-content: center;
    width: 100%; }
  #offer .metering, #offer .installment {
    width: 100%; }
  #offer .img {
    display: none; }
  #offer h2, #offer p {
    text-align: center; }
  #offer p {
    width: 100%;
    max-width: 100%;
    margin: 15px 0; }
  #offer .form {
    width: 100%;
    max-width: 100%; }
  #questions .box {
    height: auto; }
  #price {
    padding-top: 230px; }
  footer .info .copy {
    text-align: center; }
  footer .info .promicom {
    width: 100%;
    text-align: center; }
  footer .info .pr {
    padding-right: 0; }
    footer .info .pr .contacts {
      text-align: center; }
  #calculate #calculate-tab-content {
    position: relative;
    z-index: 3; }
    #calculate #calculate-tab-content .form {
      flex-direction: column;
      justify-content: center;
      align-items: center; }
    #calculate #calculate-tab-content .calculate-conf {
      width: 100%; }
  #calculate #tab {
    flex-direction: column; }
    #calculate #tab .nav-link {
      font-size: 16px;
      width: 100%;
      text-align: center;
      padding: 10px;
      display: block;
      height: 100%; }
      #calculate #tab .nav-link:after {
        font-size: 60px; }
  #calculate .calculate-tab {
    flex-direction: column; }
    #calculate .calculate-tab .conf {
      justify-content: center;
      align-items: center;
      width: 100%; } }

@media (max-width: 360px) {
  #offer .form {
    flex-direction: column; } }

@font-face {
  font-family: Whitney-Medium;
  src: url("/fonts/Whitney-Medium.eot?#iefix") format("embedded-opentype"), url("/fonts/Whitney-Medium.otf") format("opentype"), url("/fonts/Whitney-Medium.woff") format("woff"), url("/fonts/Whitney-Medium.ttf") format("truetype"), url("/fonts/Whitney-Medium.svg#Whitney-Medium") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: Whitney-Black;
  src: url("/fonts/Whitney-Black.eot?#iefix") format("embedded-opentype"), url("/fonts/Whitney-Black.otf") format("opentype"), url("/fonts/Whitney-Black.woff") format("woff"), url("/fonts/Whitney-Black.ttf") format("truetype"), url("/fonts/Whitney-Black.svg#Whitney-Black") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: Whitney-Bold;
  src: url("/fonts/Whitney-Bold.eot?#iefix") format("embedded-opentype"), url("/fonts/Whitney-Bold.otf") format("opentype"), url("/fonts/Whitney-Bold.woff") format("woff"), url("/fonts/Whitney-Bold.ttf") format("truetype"), url("/fonts/Whitney-Bold.svg#Whitney-Bold") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: Whitney-MediumItalic;
  src: url("/fonts/Whitney-MediumItalic.eot?#iefix") format("embedded-opentype"), url("/fonts/Whitney-MediumItalic.otf") format("opentype"), url("/fonts/Whitney-MediumItalic.woff") format("woff"), url("/fonts/Whitney-MediumItalic.ttf") format("truetype"), url("/fonts/Whitney-MediumItalic.svg#Whitney-MediumItalic") format("svg");
  font-weight: normal;
  font-style: normal; }
